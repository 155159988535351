import * as React from "react";

import { Box, Container, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  rocket: { height: 80 },
  hero: {
    background: "#539DBF",
    paddingTop: 50,
    paddingBottom: 50,
  },
  heroInner: {
    display: "flex",
    alignItems: "center",
    color: "#FFF",
  },
  heroTitle: {
    fontSize: 36,
    paddingLeft: 24,
  },
});

export const Hero = () => {
  const classes = useStyles();

  return (
    <Box className={classes.hero}>
      <Container maxWidth="lg">
        <Box className={classes.heroInner}>
          <img src="/images/rocket.svg" className={classes.rocket} />
          <Typography variant="h1" className={classes.heroTitle}>
            Deploying the Serverless Stack
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};
